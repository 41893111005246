<template>
	<div class="pa-4">
		<div v-if="!noDataFound">
			<div v-if="isLoading" color="grey lighten-4" class="pa-3">
				<v-row>
					<v-col cols="6" v-for="i in 4" :key="i">
						<v-skeleton-loader
							class="mx-auto"
							width="100%"
							type="card"
						></v-skeleton-loader>
					</v-col>
				</v-row>
			</div>
			<div v-else class="d-flex flex-column">
				<v-row
					v-if="scheduledPosts && scheduledPosts.length > 0"
					class="mx-0"
				>
					<v-col
						cols="6"
						v-for="post in scheduledPosts"
						:key="post._id"
					>
						<v-card>
							<div v-if="post.post.media" class="container">
								<div
									v-for="media in post.post.media"
									:key="media.name"
								>
									<v-img
										v-if="media.mediaFormat === 'PHOTO'"
										:src="media.sourceUrl"
									></v-img>
									<div v-else-if="media.mediaFormat === 'VIDEO'">
										<video controls>  
      										<source :src="media.sourceUrl" type="video/mp4">  
      										Your browser does not support the html video tag.  
    									</video>  
									</div>
								</div>
							</div>

							<p class="pa-3 body-2">{{ post.post.summary }}</p>
							<p
								class="ma-2"
								style="color: red"
								v-if="
									post.errorMessage &&
									post.status === 'FAILED'
								"
							>
								Error Cause: {{ post.errorMessage }}
							</p>
							<v-divider></v-divider>
							<v-card-actions>
								<UnoEditButton
									@click.native="editPost(post)"
								></UnoEditButton>
								<v-chip
									class="ml-3"
									:color="
										post.status === 'QUEUED'
											? 'yellow'
											: 'red'
									"
									>{{ post.status }}</v-chip
								>
								<v-spacer></v-spacer>
								<UnoDeleteButton
									@confirm="deleteScheduledPost(post._id)"
								></UnoDeleteButton>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
				<div v-if="showPostDrawer">
					<CreatePostDrawer
						@close-drawer="closeDrawer($event)"
						@on-post-update="updateScheduledPost($event)"
						:initial-data="post.post"
						:post-id="post._id"
						:scheduledTimeStamp="post.scheduledTime"
						:saving="isSaving"
					></CreatePostDrawer>
				</div>
			</div>
			<Paginator
				v-if="paginator"
				:paginator="paginator"
				:url="getUrl()"
				@update="changePage($event)"
			></Paginator>
		</div>
		<NoData v-else></NoData>
	</div>
</template>

<script>
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import NoData from '../../../components/NoData'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import CreatePostDrawer from './CreatePostDrawer'
import Paginator from '@/components/Paginator'

export default {
	components: {
		UnoEditButton,
		UnoDeleteButton,
		CreatePostDrawer,
		Paginator,
		NoData,
	},
	data() {
		return {
			showPostDrawer: false,
			post: {},
			isLoading: false,
			noDataFound: false,
			isSaving: false,
			scheduledPosts: [],
			paginator: null,
		}
	},
	computed: {
		locationName() {
			return this.$cookies.get('gmb-selected-location')
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	created() {
		this.getScheduledPosts()
	},

	methods: {
		getScheduledPosts() {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/gmb/posts/schedule/fetch-all`,
				params: {
					name: this.locationName,
				},
			})
				.then((response) => {
					this.isLoading = false
					this.$nextTick(function () {
						this.scheduledPosts = response?.data?.data?.data
						this.paginator = response.data?.data?.paginator
						if (this.scheduledPosts.length === 0)
							this.noDataFound = true
					})
				})
				.catch((err) => {
					if (err.response.status == 404) this.noDataFound = true
					this.isLoading = false
					this.$snackbar.notify({
						message: err?.response?.data?.message,
						color: 'error',
					})
				})
		},
		updateScheduledPost(data) {
			this.showPostDrawer = false
			const post = this.post
			post.post.summary = data.summary
			post.post.media = data.media
			post.post.callToAction = data.callToAction
			post.scheduledTime = data.scheduledTime
			const projectId = this.$store.state.app.projectId
			console.log(post.post)
			this.saving = true
			this.axios({
				method: 'put',
				url: `/${projectId}/gmb/posts/schedule/update/${data.id}`,
				data: post,
			})
				.then((response) => {
					this.saving = false
					this.showPostDrawer = false
					if (response?.data?.success) {
						this.$snackbar.notify({
							message: response.data?.message,
							color: 'green',
						})
						setTimeout(() => {
							this.getScheduledPosts()
						}, 0)
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
					this.saving = false
				})
		},
		deleteScheduledPost(postId) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'delete',
				url: `/${projectId}/gmb/posts/schedule/delete/${postId}`,
			})
				.then((response) => {
					if (response?.data?.message) {
						this.$snackbar.notify({
							message: response?.data?.message,
							color: 'yellow',
						})
						setTimeout(() => {
							this.getScheduledPosts()
						}, 0)
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		closeDrawer() {
			this.showPostDrawer = !this.showPostDrawer
		},
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/gmb/posts/schedule/fetch-all`
			return url
		},
		editPost(postData) {
			this.post = postData
			this.showPostDrawer = true
		},
		changePage(data) {
			this.scheduledPosts = data.data
			this.paginator = data.paginator
		},
	},
}
</script>

<style scoped>
.footer {
	background-color: var(--blue);
	height: 60px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
.container {
	position: relative;
	text-align: center;
	color: white;
	padding: 0px !important;
}
.top-left {
	position: absolute;
	top: 0px;
	background: linear-gradient(rgba(0, 0, 0, 0.54), transparent);
	height: 150px;
	color: #f1f1f1;
	width: 100%;
	padding: 20px;
	text-align: left;
}
</style>
